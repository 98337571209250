import { useState, useReducer } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import axios from 'axios';


const MODALIDADES = [
  {
    value: 'MENSUAL',
    label: 'Mensual',
  },
  {
    value: 'TRIMESTRAL',
    label: 'Trimestral',
  },
  {
    value: 'SEMESTRAL',
    label: 'Semestral',
  },
  {
    value: 'ANUAL',
    label: 'Anual',
  },
  {
    value: 'SIN MONITOREO',
    label: 'Sin Monitoreo',
  },
];

const MESES = [
  {
    value: 'ENE',
    label: 'Enero',
  },
  {
    value: 'FEB',
    label: 'Febrero',
  },
  {
    value: 'MAR',
    label: 'Marzo',
  },
  {
    value: 'ABR',
    label: 'Abril',
  },
  {
    value: 'MAY',
    label: 'Mayo',
  },
  {
    value: 'JUN',
    label: 'Junio',
  },
  {
    value: 'JUL',
    label: 'Julio',
  },
  {
    value: 'AGO',
    label: 'Agosto',
  },
  {
    value: 'SEP',
    label: 'Septiembre',
  },
  {
    value: 'OCT',
    label: 'Octubre',
  },
  {
    value: 'NOV',
    label: 'Noviembre',
  },
  {
    value: 'DIC',
    label: 'Diciembre',
  },
];

const STATI = [
  {
    value: 'ACTIVO',
    label: 'Activo',
  },
  {
    value: 'SUSPENDIDO',
    label: 'Suspendido',
  },
  {
    value: 'CANCELADO',
    label: 'Cancelado',
  },
  {
    value: 'CORTESIA',
    label: 'Cortesía',
  },
  {
    value: 'PENDIENTE',
    label: 'Pendiente',
  },
];

const MOTIVES = [
  {
    value: '',
    label: '',
  },
  {
    value: 'CAMBIO DOMICILIO',
    label: 'Cambio de Domicilio',
  },
  {
    value: 'ADEUDO',
    label: 'Adeudo',
  },
  {
    value: 'FALLO COMUNICACION',
    label: 'Fallo de Comunicación',
  },
  {
    value: 'REMODELACION',
    label: 'Remodelación',
  },
  {
    value: 'A PRUEBA',
    label: 'A Prueba',
  },
];

const SELLERS = [
  {
    value: 'VICTOR ALMEIDA',
    label: 'Victor Almeida',
  },
  {
    value: 'SANTIAGO FERNANDEZ',
    label: 'Santiago Fernández',
  },
  {
    value: 'EDUARDO BEGONA',
    label: 'Eduardo Begoña',
  },
  {
    value: 'EDUARDO BEGONA NIETO',
    label: 'Eduardo Begoña Nieto',
  },
  {
    value: 'EQUIPOS ELECTRONICOS DE SEGURIDAD PRIVADA',
    label: 'Equipos Electrónicos de Seguridad Privada',
  },
  {
    value: 'ROBERTO COSÍO',
    label: 'Roberto Cosío',
  },
];

const YN = [
  {
    value: 'SI',
    label: 'Sí'
  },
  {
    value: 'NO',
    label: 'No'
  },
];

const PROMO = [
  {
    value: '11X12',
    label: '11x12'
  },
  {
    value: 'NINGUNA',
    label: 'Ninguna'
  },
]

const GIROS = [
  {
    value: 'CASA HABITACION',
    label: 'Casa-Habitación'
  },
  {
    value: 'NEGOCIO',
    label: 'Negocio'
  },
  {
    value: 'ESCUELAS',
    label: 'Escuela'
  },
  {
    value: 'CEDIS/ALMACEN',
    label: 'CEDIS/Almacén'
  },
];

// ['RF','LT','GPRS','IP']
const TECH = [
  {
    value: 'RF',
    label: 'RF'
  },
  {
    value: 'LT',
    label: 'LT',
  },
  {
    value: 'GPRS',
    label: 'GPRS'
  },
  {
    value: 'IP',
    label: 'IP'
  }
]

const FORMAS_PAGO = [
  {
    value: "1 Efectivo",
    label: "01 Efectivo"
  },
  {
    value: "2 Cheque nominativo",
    label: "02 Cheque nominativo"
  },
  {
    value: "3 Transferencia electrónica de fondos",
    label: "03 Transferencia electrónica de fondos"
  },
  {
    value: "4 Tarjeta de crédito",
    label: "04 Tarjeta de crédito"
  },
  {
    value: "5 Monedero electrónico",
    label: "05 Monedero electrónico"
  },
  {
    value: "6 Dinero electrónico",
    label: "06 Dinero electrónico"
  },
  {
    value: "8 Vales de despensa",
    label: "08 Vales de despensa"
  },
  {
    value: "12 Dación en pago",
    label: "12 Dación en pago"
  },
  {
    value: "13 Pago por subrogación",
    label: "13 Pago por subrogación"
  },
  {
    value: "14 Pago por consignación",
    label: "14 Pago por consignación"
  },
  {
    value: "15 Condonación",
    label: "15 Condonación"
  },
  {
    value: "17 Compensación",
    label: "17 Compensación"
  },
  {
    value: "23 Novación",
    label: "23 Novación"
  },
  {
    value: "24 Confusión",
    label: "24 Confusión"
  },
  {
    value: "25 Remisión de deuda",
    label: "25 Remisión de deuda"
  },
  {
    value: "26 Prescripción o caducidad",
    label: "26 Prescripción o caducidad"
  },
  {
    value: "27 A satisfacción del acreedor",
    label: "27 A satisfacción del acreedor"
  },
  {
    value: "28 Tarjeta de débito",
    label: "28 Tarjeta de débito"
  },
  {
    value: "29 Tarjeta de servicios",
    label: "29 Tarjeta de servicios"
  },
  {
    value: "30 Aplicación de anticipos",
    label: "30 Aplicación de anticipos"
  },
  {
    value: "99 Por definir",
    label: "99 Por definir"
  },
]

const MEDIOS_PAGO = [
  {
    value: 'PUE',
    label: 'PUE'
  },
  {
    value: 'PPD',
    label: 'PPD'
  },
]

const USOS_CFDI = [
  {
    value: "G01",
    label: "G01 Adquisición de mercancías"
  },
  {
    value: "G02",
    label: "G02 Devoluciones, descuentos o bonificaciones"
  },
  {
    value: "G03",
    label: "G03 Gastos en general"
  },
  {
    value: "I01",
    label: "I01 Construcciones"
  },
  {
    value: "I02",
    label: "I02 Mobilario y equipo de oficina por inversiones"
  },
  {
    value: "I03",
    label: "I03 Equipo de transporte"
  },
  {
    value: "I04",
    label: "I04 Equipo de cómputo y accesorios"
  },
  {
    value: "I05",
    label: "I05 Dados, troqueles, moldes, matrices y herramental"
  },
  {
    value: "I06",
    label: "I06 Comunicaciones telefónicas"
  },
  {
    value: "I07",
    label: "I07 Comunicaciones satelitales"
  },
  {
    value: "I08",
    label: "I08 Otra maquinaria y equipo"
  },
  {
    value: "D01",
    label: "D01 Honorarios médicos, dentales y gastos hospitalarios"
  },
  {
    value: "D02",
    label: "D02 Gastos médicos por incapacidad o discapacidad"
  },
  {
    value: "D03",
    label: "D03 Gastos funerales"
  },
  {
    value: "D04",
    label: "D04 Donativos"
  },
  {
    value: "D05",
    label: "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)"
  },
  {
    value: "D06",
    label: "D06 Aportaciones voluntarias al SAR"
  },
  {
    value: "D07",
    label: "D07 Primas por seguros de gastos médicos"
  },
  {
    value: "D08",
    label: "D08 Gastos de transportación escolar obligatoria"
  },
  {
    value: "D09",
    label: "D09 Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones"
  },
  {
    value: "D10",
    label: "D10 Pagos por servicios educativos (colegiaturas)"
  }, 
  {
    value: "P01",
    label: "P01 Por definir"
  }, 
]

const initialState = {
  dealers: '',
  nombreComercial: '',
  razonSocialMonitoreo: '',
  razonSocialEquipo: '',
  modalidadPago: '',
  status: 'ACTIVO',
  motivoStatus: '',
  mesPago: '',
  cuotaMensual: '',
  cuotaMensualTotal: '',
  pctIva: '16',
  fechaInicio: dayjs(),
  adminpaq: '',
  vendedor: '',
  videoverificacion: '',
  smartService: '',
  simCard: '',
  imei: '',
  promocion: 'NINGUNA',
  observaciones: '',
  rfc: '',
  telefonos: '',
  email: '',
  giro: '',
  tecnologia: '',
  contacto: '',
  cuenta: '',
  clabe: '',
  banco: '',
  formaPago: '',
  medioPago: '',
  usoCfdi: '',
  alarmcom: '',
  reqStatus: true,
  emailErr:'',
  rfcErr:'',
  phoneErr:'',
  clabeErr:'',
  contrato: ''
};

function reducer(state, action) {
  switch (action.type) {
    case 'setDealers':
      return { ...state, dealers: action.payload };
    case 'setNombreComercial':
      return { ...state, nombreComercial: action.payload };
    case 'setRazonSocialMonitoreo':
      return { ...state, razonSocialMonitoreo: action.payload };
      case 'setRazonSocialEquipo':
        return { ...state, razonSocialEquipo: action.payload };
    case 'setModalidadPago':
      return { ...state, modalidadPago: action.payload }
    case 'setStatus':
      return {
        ...state,
        status: action.payload,
        // Updates the required status of a field
        reqStatus: action.payload === 'ACTIVO'
      };
    case 'setMotivoStatus':
      return { ...state, motivoStatus: action.payload };
    case 'setMesPago':
      return { ...state, mesPago: action.payload };
    case 'setCuotaMensual':
      const newCuota = action.payload
        .replace(/[^\d\.]/, "")
        .replace(/\.+/, ".")
      return {
        ...state,
        cuotaMensual: newCuota,
        cuotaMensualTotal: newCuota
      };
    case 'setCuotaMensualTotal':
      return { ...state, cuotaMensualTotal: action.payload };
    case 'setPctIva':
      return { ...state, pctIva: action.payload };
    case 'setFechaInicio':
      return { ...state, fechaInicio: action.payload };
    case 'setAdminpaq':
      return { ...state, adminpaq: action.payload };
    case 'setVendedor':
      return { ...state, vendedor: action.payload };
    case 'setVideoverificacion':
      return { ...state, videoverificacion: action.payload };
    case 'setSimCard':
      return { ...state, simCard: action.payload };
    case 'setSmartService':
      return { ...state, smartService: action.payload };
    case 'setImei':
      return { ...state, imei: action.payload };
    case 'setPromocion':
      return { ...state, promocion: action.payload };
    case 'setObservaciones':
      return { ...state, observaciones: action.payload }
    case 'setRfc':
      const newRfc = action.payload;
      const rfcRegex = /([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})/
      const rfcTest = rfcRegex.test(newRfc);
      let rfcError='';
      if(!rfcTest && newRfc!==''){
        rfcError = 'Valida la estructura del RFC'
      }
      return { ...state, rfc: action.payload, rfcErr: rfcError};
    case 'setTelefonos':
      const newPhone = action.payload.replace(" ","").replace("-","")
      const phoneTest = /\d{10}/.test(newPhone);
      let phoneError = '';
      if(!phoneTest && newPhone!==''){
        phoneError = 'Los teléfonos deben ser de 10 dígitos'
      }
      return { ...state, telefonos: newPhone , phoneErr: phoneError};
    case 'setEmail':
      const newEmail = action.payload;
      const emailRegex = /[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}(\.[\w+])*/.test(newEmail);
      let err='';
      if(!emailRegex && newEmail!==''){
        err = 'El correo electrónico no tiene estructura correct'
      }
      return { ...state, email: action.payload, emailErr: err };
    case 'setGiro':
      return { ...state, giro: action.payload };
    case 'setTecnologia':
      return { ...state, tecnologia: action.payload };
    case 'setContacto':
      return { ...state, contacto: action.payload };
    case 'setContrato':
      return { ...state, contrato: action.payload };
    case 'setCuenta':
      return { ...state, cuenta: action.payload };
    case 'setClabe':
      const newClabe = action.payload.replace(" ", "").replace("-", "");
      const clabeTest = /^\d{18}$/.test(newClabe);
      let clabeError = '';
      if(!clabeTest && newClabe!==''){
        clabeError = 'La clabe debe ser de 18 dígitos'
      }
      return { ...state, clabe: newClabe, clabeErr: clabeError};
    case 'setBanco':
      return { ...state, banco: action.payload };
    case 'setFormaPago':
      return { ...state, formaPago: action.payload };
    case 'setMedioPago':
      return { ...state, medioPago: action.payload };
    case 'setUsoCfdi':
      return { ...state, usoCfdi: action.payload };
    case 'setAlarmcom':
      return { ...state, alarmcom: action.payload };
    case 'reset':
      return initialState;
    default:
      return state;
  }
}

const theme = createTheme();

function Capture() {
  // Handles the full state object
  const [state, dispatch] = useReducer(reducer, initialState);
  const [helperText, setHelperText] = useState('')
  const [btnColor, setBtnColor] = useState('primary')
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(state);
    setLoading(true);
    try {
      const response = await axios.post(
        '/inv/add_invoice', state , {headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Set the content type
          }})
      setHelperText('');
      setBtnColor('primary');
      if (response.status === 209){
        setBtnColor('error');
        const data = response.data
        console.log(response)
        setHelperText(data.message);
      }
      if (response.status === 208){
        setBtnColor('success');
        const data = response.data
        console.log(response)
        setHelperText(data.message);
      }
      if (response.status === 207){
        setBtnColor('error');
        setHelperText('Valida que los datos sean correctos');
      }
      if (response.status === 200){
        setBtnColor('success');
        setHelperText('Cliente agregado con éxito');
        dispatch({type: 'reset'});
      }
      } catch {
        setHelperText('Error interno en el servidor, contacta a BlkBx');
        setBtnColor('error');
      }
      setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5vh', mb: '5vh' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} sx={{margin: 0}}>
              <TextField
                fullWidth
                id="dealers"
                label="Dealer"
                required={state.reqStatus}
                value={state.dealers}
                onChange={(e) =>
                  dispatch({ type: 'setDealers', payload: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="nombreComercial"
                label="Nombre Comercial"
                required={state.reqStatus}
                value={state.nombreComercial}
                onChange={(e) =>
                  dispatch({
                    type: 'setNombreComercial',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="razonSocialMonitoreo"
                label="Razón Social Monitoreo"
                required={state.reqStatus && state.razonSocialEquipo === ''}
                value={state.razonSocialMonitoreo}
                onChange={(e) =>
                  dispatch({
                    type: 'setRazonSocialMonitoreo',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="razonSocialEquipo"
                label="Razón Social Equipo"
                required={state.reqStatus && state.razonSocialMonitoreo === ''}
                value={state.razonSocialEquipo}
                onChange={(e) =>
                  dispatch({
                    type: 'setRazonSocialEquipo',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                select
                id="modalidadPago"
                label="Modalidad de Pago"
                required={state.reqStatus}
                value={state.modalidadPago}
                onChange={(e) =>
                  dispatch({
                    type: 'setModalidadPago',
                    payload: e.target.value
                  }
                  )
                }
              >
                {MODALIDADES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                select
                required
                id="status"
                label="Status"
                value={state.status}
                onChange={(e) =>
                  dispatch({
                    type: 'setStatus',
                    payload: e.target.value
                  }
                  )
                }
              >
                {STATI.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                select
                required={!state.reqStatus}
                id="motivoStatus"
                label="Motivo de Status"
                value={state.motivoStatus}
                onChange={(e) =>
                  dispatch({
                    type: 'setMotivoStatus',
                    payload: e.target.value
                  }
                  )
                }
              >
                {MOTIVES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                select
                id="mesPago"
                label="Mes de Pago"
                required={state.reqStatus}
                value={state.mesPago}
                onChange={(e) =>
                  dispatch({
                    type: 'setMesPago',
                    payload: e.target.value
                  }
                  )
                }
              >
                {MESES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="cuotaMensual"
                label="Cuota Mensual"
                value={state.cuotaMensual}
                onChange={(e) =>
                  dispatch({
                    type: 'setCuotaMensual',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="cuotaMensualTotal"
                label="Cuota Mensual Total"
                value={state.cuotaMensualTotal}
                onChange={(e) =>
                  dispatch({
                    type: 'setCuotaMensualTotal',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                fullWidth
                id="pctIva"
                label="IVA (%)"
                required
                value={state.pctIva}
                onChange={(e) =>
                  dispatch({
                    type: 'setPctIva',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                id="fechaInicio"
                label="Fecha de Inicio"
                type="date"
                required={state.reqStatus}
                value={state.fechaInicio}
                onChange={(e) =>
                  dispatch({
                    type: 'setFechaInicio',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="adminpaq"
                label="AdminPaq"
                value={state.adminpaq}
                onChange={(e) =>
                  dispatch({
                    type: 'setAdminpaq',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                select
                id="vendedor"
                label="Vendedor"
                required={state.reqStatus}
                value={state.vendedor}
                onChange={(e) =>
                  dispatch({
                    type: 'setVendedor',
                    payload: e.target.value
                  }
                  )
                }
              >
                {SELLERS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="videoverificacion"
                label="Videoverificación"
                required={state.reqStatus}
                value={state.videoverificacion}
                onChange={(e) =>
                  dispatch({
                    type: 'setVideoverificacion',
                    payload: e.target.value
                  }
                  )
                }
              >
                {YN.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="smartService"
                label="Smart Service"
                required={state.reqStatus}
                value={state.smartService}
                onChange={(e) =>
                  dispatch({
                    type: 'setSmartService',
                    payload: e.target.value
                  }
                  )
                }
              >
                {YN.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="simCard"
                label="SimCard"
                required={state.reqStatus}
                value={state.simCard}
                onChange={(e) =>
                  dispatch({
                    type: 'setSimCard',
                    payload: e.target.value
                  }
                  )
                }
              >
                {YN.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                id="imei"
                label="IMEI"
                required={state.simCard == 'SI'}
                value={state.imei}
                onChange={(e) =>
                  dispatch({
                    type: 'setImei',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="promocion"
                label="Promoción"
                required={state.reqStatus}
                value={state.promocion}
                onChange={(e) =>
                  dispatch({
                    type: 'setPromocion',
                    payload: e.target.value
                  }
                  )
                }
              >
                {PROMO.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="observaciones"
                label="Observaciones"
                value={state.observaciones}
                onChange={(e) =>
                  dispatch({
                    type: 'setObservaciones',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="rfc"
                label="RFC"
                error={state.rfcErr!==''}
                helperText={state.rfcErr}
                value={state.rfc}
                onChange={(e) =>
                  dispatch({
                    type: 'setRfc',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="telefonos"
                label="Teléfonos (Separar con comas)"
                error={state.phoneErr !== ''}
                helperText = {state.phoneErr}
                value={state.telefonos}
                onChange={(e) =>
                  dispatch({
                    type: 'setTelefonos',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="email"
                label="Correos Electrónicos (Separar con comas)"
                value={state.email}
                error={state.emailErr!==''}
                helperText={state.emailErr}
                onChange={(e) =>
                  dispatch({
                    type: 'setEmail',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="giro"
                label="Giro"
                required={state.reqStatus}
                value={state.giro}
                onChange={(e) =>
                  dispatch({
                    type: 'setGiro',
                    payload: e.target.value
                  }
                  )
                }
              >
                {GIROS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="tecnologia"
                label="Tecnología"
                value={state.tecnologia}
                onChange={(e) =>
                  dispatch({
                    type: 'setTecnologia',
                    payload: e.target.value
                  }
                  )
                }
              >
                {TECH.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="contacto"
                label="Contacto(s)"
                value={state.contacto}
                required={state.reqStatus}
                onChange={(e) =>
                  dispatch({
                    type: 'setContacto',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="contrato"
                label="Contrato (vacío=automático)"
                value={state.contrato}
                onChange={(e) =>
                  dispatch({
                    type: 'setContrato',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                id="cuenta"
                label="Cuenta"
                value={state.cuenta}
                onChange={(e) =>
                  dispatch({
                    type: 'setCuenta',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="clabe"
                label="CLABE"
                value={state.clabe}
                error={state.clabeErr!==''}
                helperText={state.clabeErr}
                onChange={(e) =>
                  dispatch({
                    type: 'setClabe',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                id="banco"
                label="Banco"
                value={state.banco}
                onChange={(e) =>
                  dispatch({
                    type: 'setBanco',
                    payload: e.target.value
                  }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                select
                id="formaPago"
                label="Forma de Pago"
                value={state.formaPago}
                onChange={(e) =>
                  dispatch({
                    type: 'setFormaPago',
                    payload: e.target.value
                  }
                  )
                }
              >
                {/* TODO: Add formas de pago */}
                {FORMAS_PAGO.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="medioPago"
                label="Medio de Pago"
                value={state.medioPago}
                onChange={(e) =>
                  dispatch({
                    type: 'setMedioPago',
                    payload: e.target.value
                  }
                  )
                }
              >
                {/* TODO: Add formas de pago */}
                {MEDIOS_PAGO.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                id="usoCfdi"
                label="Uso CFDI"
                value={state.usoCfdi}
                onChange={(e) =>
                  dispatch({
                    type: 'setUsoCfdi',
                    payload: e.target.value
                  }
                  )
                }
              >
                {/* TODO: Add fusos de cfdi */}
                {USOS_CFDI.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                select
                id="alarmcom"
                label="ALARM.COM"
                value={state.alarmcom}
                onChange={(e) =>
                  dispatch({
                    type: 'setAlarmcom',
                    payload: e.target.value
                  }
                  )
                }
              >
                {YN.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <Tooltip text={helperText} placement='bottom' arrow>
                <Button
                  type="submit"
                  fullWidth
                  disabled={
                    (state.phoneErr || state.rfcErr || state.emailErr ||
                      state.clabeErr || loading)
                  }
                  sx={{ height: 54 }}
                  variant="contained"
                  color={btnColor}>
                Capturar
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Typography 
            align='center' 
            variant='h5'
            sx={{mb:10}}
            color = {btnColor}
          >
            {helperText}
          </Typography>
      </Container>
    </ThemeProvider>
  )
}

export default Capture;