import React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, Typography, TextField, Autocomplete, Checkbox, FormControlLabel, Button } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const dummyProducts = [
  { id: 1, name: "Product 1", price: 10 },
  { id: 2, name: "Product 2", price: 20 },
  { id: 3, name: "Product 3", price: 30 },
  { id: 4, name: "Product 4", price: 40 },
  { id: 5, name: "Product 5", price: 50 },
  // Add more products as needed
];

const monitoreos = [
  {value: 0, label: "0.- Sin monitoreo", price: 0},
  {
    value: 53,
    label: "1.- Monitoreo RF/IP, Videoverificación, Aplicación, Historiales",
    price: 495.00
  },
  {
    value: 54,
    label: "2.- Monitoreo RF/IP",
    price: 395.00
  },
  {
    value: 55,
    label: "3.- Monitoreo GPRS, Videoverificación",
    price: 580.00
  },
  {
    value: 56,
    label: "4.- Monitoreo GPRS",
    price: 480.00
  },
  {
    value: 57,
    label: "5.- Monitoreo Alarm.com, Plataforma web, App, Videoverificación",
    price: 750.00
  },
  {
    value: 58,
    label: "6.- Monitoreo Alarm.com, Programado",
    price: 300.00
  },
]

const initialState = {
  manoObra: 0,
  cliente: "",
  atn: "",
  tel: "",
  email: "",
  direccion: ""
}

const reducer = (state, action) => {
  switch(action.type) {
    case "setManoObra":
      let newManoObra = action.payload
        .replace(/[^\d\.]/, "")
        .replace(/\.+/, ".");
      if (newManoObra === "") {
        newManoObra = 0
      }
      return {
        ...state,
        manoObra: parseFloat(newManoObra),
      };
    case "setCliente":
      return{
        ...state, cliente: action.payload
      }
    case "setAtn":
      return{
        ...state, atn: action.payload
      }
    case "setTel":
      return{
        ...state, tel: action.payload
      }
    case "setEmail":
      return{
        ...state, email: action.payload
      }
    case "setDireccion":
      return{
        ...state, direccion: action.payload
      }
  }
}

const Quotes = () => {

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [selectedProducts, setSelectedProducts] = React.useState(
    [{ product: null, amount: '' }]
  );
  const maxProducts = 30;
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [monitoreo, setMonitoreo] = React.useState(
    {value: 0, label: "0.- Sin monitoreo", price: 0})

  // monitoreo cerca energizada
  const [sm1, setSm1] = React.useState(false)
  // Reportes mensuales via email
  const [sm2, setSm2] = React.useState(false)
  // Smart services
  const [sm3, setSm3] = React.useState(false)

  const handleSm1 = (event) => {
    setSm1(event.target.checked);
  };
  const handleSm2 = (event) => {
    setSm2(event.target.checked);
  };
  const handleSm3 = (event) => {
    setSm3(event.target.checked);
  };

  const handleMonitoreo = (value) => {
    setMonitoreo({...monitoreo, ...value})
  }

  const handleProductChange = (index, value) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts[index].product = value;
    setSelectedProducts(newSelectedProducts);

    if (index === selectedProducts.length - 1 && value) {
      if (selectedProducts.length < maxProducts) {
        setSelectedProducts([...selectedProducts, { product: null, amount: '' }]);
      }
    }
  };

  const handleAmountChange = (index, event) => {
    const newSelectedProducts = [...selectedProducts];
    if (event.target.value >= 0){
      newSelectedProducts[index].amount = event.target.value;
    }   
    setSelectedProducts(newSelectedProducts);
  };

  const getTotalPrice = (product, amount) => {
    if (product && amount) {
      return product.price * amount;
    }
    return 0;
  };

  const total = selectedProducts.reduce((sum, item) => {
    return sum + getTotalPrice(item.product, item.amount);
  }, 0);

  React.useEffect(() =>{
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/inv/allproducts');
        setProducts([...response.data]);
      } catch (err) {
        console.error(err);
      } finally {
        console.log(products)
        setLoading(false);
      }
    };
    fetchProducts();
  }, [])
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post(
      '/inv/get_xlsx',
      {
        'products' : selectedProducts,
        'sm1' : sm1,
        'sm2' : sm2,
        'sm3': sm3,
        'cliente': state.cliente,
        'atn': state.atn,
        'tel': state.tel,
        'email': state.email,
        'direccion': state.direccion,
        'monitoreo': monitoreo,
        'manoObra': state.manoObra
      },
      {headers: {
        "Content-Type":'application/json'
      }, responseType: 'blob'}
    ).then((r) => {
      console.log(r)
      const href = window.URL.createObjectURL(r.data);

      const anchorElement = document.createElement('a');

      anchorElement.href = href;
      anchorElement.download = "cotizacion.xlsx";

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    }).catch((err) => {
      console.error(err)
    })
  }

  return (
    <Grid container spacing={3} sx={{ml:3, mr:3, mt:1}}>
      <Grid item md={6}>
        <Typography
          variant='h5'
          sx={{mb:1}}
        >
          Cotizador
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField 
              id="cliente" 
              label="Cliente" 
              variant="standard" 
              value={state.cliente}
              onChange={(e) => {
                dispatch(
                  {
                    type: "setCliente",
                    payload: e.target.value
                  }
                )
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField 
              id="atn" 
              label="At'n" 
              variant="standard" 
              value={state.atn}
              onChange={(e) => {
                dispatch(
                  {
                    type: "setAtn",
                    payload: e.target.value
                  }
                )
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField 
              id="telefono" 
              label="Teléfono" 
              variant="standard" 
              value={state.tel}
              onChange={(e) => {
                dispatch(
                  {
                    type: "setTel",
                    payload: e.target.value
                  }
                )
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField 
              id="email" 
              label="E-mail" 
              variant="standard" 
              value={state.email}
              onChange={(e) => {
                dispatch(
                  {
                    type: "setEmail",
                    payload: e.target.value
                  }
                )
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <TextField 
              id="direccion" 
              label="Dirección" 
              variant="standard" 
              value={state.direccion}
              onChange={(e) => {
                dispatch(
                  {
                    type: "setDireccion",
                    payload: e.target.value
                  }
                )
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
                <Autocomplete 
                  options={monitoreos}
                  getOptionLabel={(option) => option.label}
                  value={monitoreo}
                  onChange={(event, value) => handleMonitoreo(value)}
                  renderInput={(params) => <TextField {...params} label="Tipo de Monitoreo" variant="standard" />}
                  fullWidth
                />
            </Grid>
          <Grid item sm={4}>
          <FormControlLabel
              label="Cerca Energizada"
              control={
                <Checkbox
                  label= "Reportes email"
                  checked={sm1}
                  onChange={handleSm1}
                />
              }
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              label="Reportes email"
              control={
                <Checkbox
                  label= "Reportes email"
                  checked={sm2}
                  onChange={handleSm2}
                />
              }
            />
          </Grid>
          <Grid item sm={4}>
          <FormControlLabel
              label="Smart Services"
              control={
                <Checkbox
                  label= "Smart Service"
                  checked={sm3}
                  onChange={handleSm3}
                />
              }
            />
          </Grid>
            <Grid item sm={12}>
              <TextField 
                id="manoobra" 
                label="Costo de mano de obra" 
                variant="standard" 
                value={state.manoObra}
                onChange={(e) => {
                  dispatch(
                    {
                      type: "setManoObra",
                      payload: e.target.value
                    }
                  )
                }}
                fullWidth
              />
            </Grid>
              {selectedProducts.map((item, index) => (
                <>
                  <Grid item sm={8}>
                    <Autocomplete
                      options={products}
                      getOptionLabel={(option) => option.code + " - " + option.name}
                      value={item.product}
                      onChange={(event, value) => handleProductChange(index, value)}
                      renderInput={(params) => <TextField {...params} label="Producto" variant="standard" />}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                  <TextField
                    label="Cantidad"
                    variant="standard"
                    value={item.amount}
                    onChange={(event) => handleAmountChange(index, event)}
                    fullWidth
                    type="number"
                  />
                  </Grid>
                </>
              ))}

          </Grid>
      </Grid>
      <Grid item md={6}>
        <Typography
          variant='h5'
          sx={{mb:1}}
        >
          Resumen
        </Typography>
        <Grid continer>
          {
            (state.manoObra !== "") &&
            (
              <Grid item sm={12}>
                <Typography variant='h7'>
                  <b>Mano de obra</b>
                </Typography>
                <br />
                <Typography variant='body'>
                  ${state.manoObra} MXN
                </Typography>
              </Grid>
            )
          }
          {
            selectedProducts.filter(item => item.product && item.amount).map((item, index) => (
              <Grid item sm={12}>
                <Typography variant='h7'>
                  <b>{item.product.name}</b>: {item.amount} unidades
                </Typography>
                <br />
                <Typography variant='body'>
                  ${item.product.price * item.amount} MXN
                </Typography>
              </Grid>
            ))
          }
              <Grid item sm={12}>
                <Typography variant='h6'>
                  <b>Subtotal</b>: ${(total+state.manoObra).toFixed(2)} MXN
                </Typography>

              </Grid>
              <Grid item sm={12}>
              <Typography variant='h6'>
                  <b>Total</b>: ${((total+state.manoObra) * 1.16).toFixed(2)} MXN
              </Typography>
              </Grid>
              {/* <Grid item sm={12}>
                <Typography variant="h6">
                  Cuotas mensuales
                </Typography>
              </Grid>
          {
            (monitoreo.value != 0) &
            <Grid item sm={12}>
                <Typography variant='h7'>
                  <b>Monitoreo</b>
                </Typography>
                <br />
                <Typography variant='body'>
                  ${monitoreo} MXN
                </Typography>
              </Grid>
          }
          {
            (sm1 == true) &
            <Grid item sm={12}>
                <Typography variant='h7'>
                  <b>Monitoreo Cerca Energizada</b>
                </Typography>
                <br />
                <Typography variant='body'>
                  $50.00 MXN
                </Typography>
              </Grid>
          }
          {
            (sm2 == true) &
            <Grid item sm={12}>
                <Typography variant='h7'>
                  <b>Reportes por email</b>
                </Typography>
                <br />
                <Typography variant='body'>
                  $50.00 MXN
                </Typography>
              </Grid>
          }
          {
            (sm3 == true) &
            <Grid item sm={12}>
                <Typography variant='h7'>
                  <b>Smart Services</b>
                </Typography>
                <br />
                <Typography variant='body'>
                  $75.00 MXN
                </Typography>
              </Grid>
          } */}
          <Grid item sm={6}>
            <Button variant="contained" fullWidth onClick={(e) => handleSubmit(e)}>
              Descargar .xlsx
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Quotes